*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container{
  display: flex;
  /* align-items: center; */
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(111, 184, 248);
}

.to-do-container{
  width: 20rem;
  /* height: 70%; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  gap: 1rem;
  background-color: white;
}
.to-do-container>h2{
  width: 100%;
  background-color: rgb(177, 72, 156);
  color: white;
  text-align: center;
  font-size: 2rem;
}
.to-do-container> :nth-child(2){
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.to-do-container> :nth-child(2)>input{
  outline: none;
  border: none;
  border-bottom: 3px solid rgb(177, 72, 156);
  padding: 4px;
  width: 12rem;
  text-align: center;
  font-size: 1.4rem;
  color: rgb(177, 72, 156);
}
.add-button{
  background-color: rgb(177, 72, 156);
  border-radius: 30px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  outline: none;
}
.add-button:hover{
  background-color: rgb(7, 209, 7);
}
.to-dos{
  align-self: flex-start;
  padding: 1rem;
  color: rgb(177, 72, 156);
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  /* overflow: auto; */
}

.t-container{
  display: flex;
  gap: 10px;
}
.cross{
  width: 2rem;
  height: 2rem;
  background-color: rgb(177, 72, 156);
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}
.cross:hover{
  background-color: red;
}
.t-container :nth-child(2){
  font-size: 1.3rem;
  font-weight: 500;
  width: 14rem;
}